<template>
  <div>
    <DetailViewSuperAdmin />
  </div>
</template>
<script>
import DetailViewSuperAdmin from '@/components/DetailViewSuperAdmin.vue'

  export default {
    name: 'TestimonyDetail',
    components: {
      DetailViewSuperAdmin
    },
    data () {
      return {
      }
    },
    created () {
      // console.log(this.$route.params.id)
    },
    methods: {
    
    },
  }
</script>